import React from 'react';
import {
    thirdwebClient,
    getUserName,
    getUserImg
} from '../../utils/text';
import {
    ConnectButton,
    useActiveAccount
} from "thirdweb/react";
import {
    inAppWallet,
    createWallet,
} from "thirdweb/wallets";

const ThirdwebButton = (props) => {
    const activeAccount = useActiveAccount();
    // console.log('activeAccount', activeAccount)

    const wallets = [
        inAppWallet({
            auth: {
                options: [
                    "google",
                    "discord",
                    "telegram",
                    "farcaster",
                    "email",
                    "x",
                    // "passkey",
                    // "phone",
                    "steam",
                    "apple",
                    "twitch",
                ],
            },
        }),
        createWallet("io.metamask"),
        createWallet("com.coinbase.wallet"),
        createWallet("me.rainbow"),
        createWallet("io.rabby"),
        createWallet("io.zerion.wallet"),
    ];

    return (
        <ConnectButton
            client={thirdwebClient}
            wallets={wallets}
            connectButton={{
                className: `${props.className ? props.className : ''} ${props.disabled ? 'disabled' : ''} ${!((props.onlyActiveAccount && activeAccount) || !props.onlyActiveAccount) ? "hide-thirdweb-button" : ''}`,
                label: props.text
            }}
            switchButton={{
                className: `${props.className ? props.className : ''} ${props.disabled ? 'disabled' : ''}`,
                label: props.text
            }}
            detailsButton={{
                className: props.className,
                connectedAccountName: getUserName(props.user),
                connectedAccountAvatarUrl: getUserImg(props.user)
            }}
            detailsModal={{
                connectedAccountName: getUserName(props.user),
                connectedAccountAvatarUrl: getUserImg(props.user),
                hideDisconnect: true
            }}
            connectModal={{ size: "compact" }}
            // onConnect={(params) => console.log('ssssss1', params)}
            // onDisconnect={(params) => console.log('ssssss2', params)}
            auth={{
                doLogin: (params) => { console.log('doLogin', params); return params },
                doLogout: () => { console.log('doLogout'); return; },
                getLoginPayload: async (params) => {
                    console.log('getLoginPayload', params);
                    if (props.verifyToken) {
                        await props.verifyToken({ ...params, type: 'thirdweb', website: process.env.website });
                    }
                    return params
                },
                isLoggedIn: (params) => { console.log('isLoggedIn', params); return params },
            }}
        />
    )
}

export default ThirdwebButton;